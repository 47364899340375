<template>
  <div class="audioPlayer" @click="play"></div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import Regions from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
import Markers from "wavesurfer.js/dist/plugin/wavesurfer.markers.js";
export default {
  data() {
      return {
        ws: null,
      };
    },
  mounted() {
    this.$nextTick(() => {
      this.ws = WaveSurfer.create({
        container: this.$el,
        responsive: true,
        mediaControls: false,
        barWidth: 2,
        audioRate: 1,
        backend: "MediaElement",
        cursorWidth: 3,
        hideScrollbar: true,
        barHeight: 1, // the height of the wave
        barGap: null, // the optional spacing between bars of the wave, if not provided will be calculated in legacy for
        height: 50,
        plugins: [Markers.create(), Regions.create()],
      });
      this.$emit('input', this.ws)
    })
  },
  methods: {
    play() {
      console.log('PLAY')
      this.ws.play()
    }
  }
};
</script>
<style scoped>

</style>
