<template>
  <v-simple-table dense fixed-header height="100%">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Speaker</th>
          <th class="text-left" style="width=300px;font-weight: normal;">
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-text-field
                v-model.trim="search"
                clearable
                prepend-icon="mdi-magnify"
                class="flex-shrink-1 flex-grow-0"
                label="search"
                height="18px"
                dense
                :persistent-hint="search && search.length > 0"
            
                :hint="`${matches} found`"
              ></v-text-field>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in rows"
          :key="row.p + row.s"
          :id="'t' + row.p"
          :class="{
            activeRow: activeRow === row,
            [sentiment(row, row.speakerName)]: true,
          }"
          @click.stop="play(row)"
        >
          <th>{{ row.speakerName }}</th>
          <td class="wrap">
            <div>
            <template v-for="(word, index) in row.words">
              <span :key="word.p"
                ><span
                  @click.stop="play(word)"
                  :class="{ active: active(word), match: match(word) }"
                  >{{ word.w }}</span
                >
                <wbr>
                <span
                  v-if="
                    row.words[index + 1] && row.words[index + 1].m !== 'punc'
                  "
                  >&nbsp;</span
                ></span
              >
            </template>
        </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>



// function scrollParentToChild(parent, child) {

// // Where is the parent on page
// var parentRect = parent.getBoundingClientRect();
// // What can you see?
// var parentViewableArea = {
//   height: parent.clientHeight,
//   width: parent.clientWidth
// };

// // Where is the child
// var childRect = child.getBoundingClientRect();
// // Is the child viewable?
// var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

// // if you can't see the child try to scroll parent
// if (!isViewable) {
//       // Should we scroll using top or bottom? Find the smaller ABS adjustment
//       const scrollTop = childRect.top - parentRect.top;
//       const scrollBot = childRect.bottom - parentRect.bottom;
//       if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
//           // we're near the top of the list
//           console.log(scrollTop, parent.scrollTop)
//           parent.scrollTop += scrollTop;
//       } else {
//           // we're near the bottom of the list
//           console.log(scrollTop, parent.scrollBot)
//           parent.scrollTop += scrollBot;
//       }
// }

// }


export default {
  props: ["transcript", "speakerSentiments", "time", "agent"],
  data() {
    return {
      search: "",
    };
  },
  watch: {
    activeRow(val) {
      if (val) {
        this.scrollToRow()
      }
    },
    search(val) {
      if (val) {
        setTimeout(()=>{
        let activeRow = this.$el.querySelector(".match");
        if (activeRow) {
          console.log(activeRow.parentNode.parentNode.parentNode);
          let row = activeRow.parentNode.parentNode.parentNode;
          let scroller = row.parentNode.parentNode.parentNode;
          console.log({row,scroller})
        //   console.log( scroller.scrollTop, row.getBoundingClientRect())
        //   scroller.scrollTop = row.getBoundingClientRect().top

            // scrollParentToChild(scroller,row)
            if(row){
                row.scrollIntoView({block: "nearest", inline: "nearest"});

            }
        }
    },100)
      } else{
        this.scrollToRow()

      }
    },
  },
  methods: {
    formatAgentName(name) {
      return name !== 'Agent' ? name : this.agent;
    },
    scrollToRow(){
        let activeRow = this.$el.querySelector(".activeRow");
        if (!activeRow) activeRow = this.$el.querySelector("tr");
          activeRow.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
    },
    match(item) {
      if (!this.search) return false;
      return item.w.toLowerCase().includes(this.search.toLowerCase())
        ? true
        : false;
    },
    
    play(item) {
      this.$emit("play", item.s / 1000);
    },
    active(item) {
      let time = this.time * 1000;
      return item.s <= time && item.e >= time;
    },
    activeRow2(item) {
      let time = this.time * 1000;
      return item.s <= time && item.e >= time;
    },
    sentiment(item, speakerName) {
      let sentiment = this.speakerSentiments.filter((s) => {
        return s.speakerName == speakerName;
      })[0];
      if (!sentiment) return null;
      let s = sentiment.sentimentValues.filter((s) => {
        return s.s <= item.s && s.e >= item.e;
      })[0];
      return s.v < 0 ? "negative" : s.v > 0 ? "positive" : "neutral";
    },
  },
  computed: {
    matches(){
        if (!this.search) return 0;
        return this.transcript.words.filter((w) => {
            return w.w.toLowerCase().includes(this.search.toLowerCase()) && w.m !== 'turn'
        }).length
    },
    activeRow() {
      let time = this.time * 1000;
      return this.rows.filter((r) => {
        return r.s <= time && r.e >= time;
      })[0];
    },
    rows() {
      let rows = [];
      let row = {
        speaker: '',
        speakerName: "",
        p: 0,
        c: 1,
        s: 0,
        e: 0,
        m: "",
        w: "",
        words: [],
      };
      let speakerName = null;
      let speaker = null;
      this.transcript.words.forEach((item) => {
        if (item.m === "turn") {
          if (speakerName) {
            rows.push({ ...row });
          }
          speakerName = this.formatAgentName(item.w)
          speaker = item.w;
          
          row = { speakerName, speaker, ...item, words: [] };
          return;
        } else {
          row.words.push(item);
        }
      });
      rows.push(row);
      return rows;
    },
  },
  mounted() {
    console.log(this.rows);
  },
};
</script>

<style scoped>
.active {
  font-weight: bold;
  text-decoration: underline;
}
.activeRow {
  background-color: #ccc;
}
.positive {
  background-color: rgba(0, 255, 0, 0.1);
}
.negative {
  background-color: rgba(255, 0, 0, 0.1);
}
.match {
  border: 1px solid red;
}

.wrap div {
    /* max-width: 1px;
  height: auto;
  white-space:pre-wrap; */
  overflow: hidden;
  hyphens: auto;
}
tbody th {
  width: 100px;
}
</style>