<template>
  <v-card  height="100%" class="d-flex flex-column" >
    <v-card-text class="d-flex flex-column flex-grow-1 flex-shrink-0">

    <AudioPlayer v-model="ws" />
    <Sentiment
      :time="time"
      :duration="sample.length"
      :sentiment="sample.speakerSentiments"
      @play="play"
      :agent="agent"

    />
    <div class="d-flex">
        <v-spacer></v-spacer>
        <div> {{formattedAudioTime}}</div>
       
    

    </div>
    <Transcript class="flex-grow-1 flex-shrink-0 tableHeight" :time="time" @play="play" :transcript="sample.transcript" :speakerSentiments="sample.speakerSentiments"
    :agent="agent"
    />
</v-card-text>
<v-card-actions>
    <PCI :time="time" :prediction="sample.prediction" />
    <v-spacer></v-spacer>
    <v-btn @click="getVoicebaseCall">GET</v-btn>
    <v-btn @click="audio.playPause()" fab icon small   elevation="5" :color="isPlaying ? 'red' : 'green'"      
    
        bottom right
>
        <v-icon v-if="isPlaying">mdi-pause</v-icon>
        <v-icon v-else>mdi-play</v-icon>
    </v-btn>
</v-card-actions>
  </v-card>
</template>

<script>

// import firebase from "../../firebase";
// const functions = firebase.functions();
// let getVoicebaseCallRecording = functions.httpsCallable("getVoicebaseCallRecording");


import AudioPlayer from "./AudioPlayer.vue";
import Sentiment from "./Sentiment.vue";
import PCI from "./PCI.vue";
import Transcript from "./Transcript.vue";
import sample from "./sample.json";
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export default {
  components: {
    AudioPlayer,
    Sentiment,
    Transcript,
    PCI
  },
  data() {
    return {
    loading: false,
        payload: null,
        audio:null,
      ws: null,
      time: 0,
      sample,
      isPlaying: false,
    };
  },
  methods:{
    async getVoicebaseCall(){
        //f7f4c60b-f0ab-4885-a99b-b1b60a114b22
        //B87619A2-8F31-40AB-AF03-ED6E828FC5B2
        let body = JSON.stringify({mediaId:'f7f4c60b-f0ab-4885-a99b-b1b60a114b22'})

        console.log({body})

        let resp = await fetch('https://us-central1-vipdesk-hub.cloudfunctions.net/getVoicebaseCallRecording2',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body,
        })
        console.log(resp.status)
        console.log(resp)
    //    let payload =  await getVoicebaseCallRecording('f7f4c60b-f0ab-4885-a99b-b1b60a114b22')
      
    //    console.log(payload.data)
    //    console.log(typeof payload.data)
    //    console.log(payload.data.audio)
    //    console.log(payload.data.toString('base64'))
    },
    loadData(){},
    loadAudio(){},
    play(time){
        this.ws.play(time)
    }
  },
  computed:{
    agent(){
        return this.sample.metadata.callDetails.agent.fullName
    },
    formattedAudioTime(){

      return dayjs.duration(this.time,'seconds').format('mm:ss')

  },
  },
   watch: {
    ws: {
      immediate: true,
      handler(val) {
        if (val) {
          val.load(require("./audio.wav"));

          val.on("audioprocess", (result) => {
            this.time = result;
          });
          val.on("play", () => {
            this.isPlaying = true;
          });
          val.on("pause", () => {
            this.isPlaying = false;
          });
        }
        console.log("AUDIO", val ? "LOADED" : "NOT LOADED");
      },
    },
  },
};
</script>

<style>
.tableHeight{
    height: calc(100vh - 320px);
}
</style>