<template>
    <v-alert type="warning" v-if="show" dense>
        Audio was redacted due to the possibility of credit card information being present.
    </v-alert>
</template>

<script>
export default {
    props: ['time', 'prediction'],
    computed:{
        show(){
            let time = this.time * 1000
            let found = false
            this.prediction.detectors
            .filter(item=>item.detectorName == 'PCI')
            .forEach(item=>item.detections.forEach(d=>{
                d.detectedSegments.forEach(ds=>{
                    let results = ds.occurrences.filter(o=>{
                        return o.s <= time && o.e >= time
                    })
                    if (results.length){
                        found = true
                    }
                })
            }))
            return found
        }
    },
}
</script>

<style>

</style>