<template>
  <div class="sentimentContainer">
    <template v-for="sentiment in sentiments">
    <div class="sentimentLabel" :key="sentiment.label + 'label'">{{sentiment.label}} Sentiment</div>
    <div class="sentimentRow" :key="sentiment.label + 'row'">
        <div class="sentiment" :style="{background:s.color, left: s.left,width:s.width}" v-for="s in sentiment.data" :key="s.left" @click="play(s)"></div>
    </div>
    </template>

    <div class="sentimentCursor" :style="{left: `calc(${progress} - 3px)`}"></div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            progress: 0,
        }
    },
    props:['time', 'duration', 'sentiment', 'agent'],
    watch:{
        time(val){
            this.progress = +((val / (this.duration/1000)) * 100).toFixed(1) + '%'
        }
    },
    methods:{
        formatAgentName(name) {
      return name !== 'Agent' ? name : this.agent;
    },
        play(s){
            this.$emit('play', s.start)
        }
    },
    computed:{
        sentiments(){
            return this.sentiment.map((s) => {
                return {
                    label: this.formatAgentName(s.speakerName),
                    data: s.sentimentValues.map((v) => {
                        return {
                            start: v.s/1000,
                            left: v.s / this.duration * 100 + '%',
                            width: (v.e - v.s) / this.duration * 100 + '%',
                            color: `rgba(${v.v == 0 || v.v<0 ? 255 : 0}, ${v.v == 0 || v.v > 0 ? 255 : 0}, ${v.v == 0 ? 255 : 0}, 1)`
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
    .sentimentContainer{
        position: relative;

    }
    .sentimentCursor{
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background-color: rgba(0,0,0,0.1);
    }
    .sentiment{
        position: absolute;
        height: 100%;
    }
    .sentimentRow{
        position: relative;
        width: 100%;
        height: 10px;
        background-color: #FFF;
        border: 1px solid #CCC;
    }
    .sentimentLabel{
        font-size: 10px;
    }
</style>